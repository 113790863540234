"use strict";

/**
 * @ngdoc service
 * @name jarb-input.jarbInputStore
 * @description
 *   Stores the contraints for the 'jarbInput' directive. You are supposed to fill it
 *   as soon as possible yourself. Notifies all jarbInput's when the constraints change
 *   so they can apply the constraints.
 * Factory in jarb-input.
 */
angular.module("jarb-input").factory("jarbInputSelector", function () {
  return { validationRulesFor };

  /**
   * @description Finds the validation rules for a specific validator in the constraints object.
   * @param  {validator} 'validator' is a string with the format: 'Class.field' for example: 'User.age'
   * @param  {constraints} The constraints to find the validator in.
   * @return {validator} The validation rules.
   * @throws {error} When the validator doesn't match the format 'className.fieldName'.
   * @throws {error} When the class is not found / defined in the constraints.
   * @throws {error} When the property is not defined for the class.
   */
  function validationRulesFor(validator, constraints, fail) {
    if (!fail) {
      fail = function () {
        /* Do nothing */
      };
    }

    var parts = validator.split(".");

    if (parts.length !== 2) {
      fail(
        'Validator does not match format: "Class.field" for example: "User.age"'
      );
    }

    var className = parts[0];
    var propertyName = parts[1];

    var klass = (constraints || {})[className];

    if (klass) {
      var validationRules = klass[propertyName];
      if (validationRules) {
        return _.extend(validationRules, {
          propertyName: propertyName
        });
      } else {
        fail(
          'Could not find property "' +
            propertyName +
            '" for class: "' +
            className +
            '". Make sure it is defined in the store'
        );
      }
    } else {
      fail(
        'Could not find class "' +
          className +
          '". Make sure it is defined in the store'
      );
    }
  }
});
