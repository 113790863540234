"use strict";

/**
 * @ngdoc service
 * @name jarb-input.jarbRegexProvider
 * @description
 *   Provides regexes to test JaRB input types with.
 *   The reason this is a separate from jarbInput is so it is easier to test.
 * Factory in jarb-input.
 */
angular.module("jarb-input").factory("jarbRegexProvider", function () {
  var jarbRegexProvider = {};
  jarbRegexProvider.fractionNumberRegex = fractionNumberRegex;
  jarbRegexProvider.numberRegex = numberRegex;
  return jarbRegexProvider;

  /**
   * Returns a regex that checks if the it is a valid number
   * which can have fractions. Which are the numbers behind
   * the decimal. So if the fractionLength is 5 you accept:
   * #.#####, which means 5 numbers after the decimals.
   *
   * The number can be negative or positive.
   * @param  {[type]} fractionLength The length of the fraction which is considered valid.
   * @return {[type]}                A regex which checks for fraction numbers.
   */
  function fractionNumberRegex(fractionLength) {
    return new RegExp("^-?\\d+(\\.\\d{1," + fractionLength + "})?$");
  }

  /**
   * Returns a regex which checks for a positive or negative number without
   * fractions.
   * @return {[type]} A regex that checks for whole numbers both positive or negative.
   */
  function numberRegex() {
    return /^-?\d+$/;
  }
});
