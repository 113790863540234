"use strict";

/**
 * @ngdoc service
 * @name jarb-input.jarbInputStore
 * @description
 *   Stores the contraints for the 'jarbInput' directive. You are supposed to fill it
 *   as soon as possible yourself. Notifies all jarbInput's when the constraints change
 *   so they can apply the constraints.
 * Factory in jarb-input.
 */
angular.module("jarb-input").factory("jarbInputStore", function ($http) {
  /**
   * The _constraints should has the following signature:
   *
   * {
   *   "SuperHero": {
   *     "name": {
   *       "javaType": "java.lang.String",
   *       "types": ["text"],
   *       "required": true,
   *       "minimumLength": null,
   *       "maximumLength": 50,
   *       "fractionLength": null,
   *       "radix": null,
   *       "pattern": null,
   *       "min": null,
   *       "max": null,
   *       "name": "name"
   *     },
   *     "email": {
   *       "javaType": "java.lang.String",
   *       "types": ["email", "text"],
   *       "required": true,
   *       "minimumLength": null,
   *       "maximumLength": 255,
   *       "fractionLength": null,
   *       "radix": null,
   *       "pattern": null,
   *       "min": null,
   *       "max": null,
   *       "name": "email"
   *     }
   * }
   *
   * The keys represent the name of the class, in the above case 'SuperHero', each class
   * has fields such as the 'name', and 'email', these are described in an object of the
   * same name. These 'validator' objects look like this:
   *
   * {
   *   "javaType": string,          // The Java class name of this validator
   *   "types":Array<string>,       // The type that closest represents this validator
   *   "required":true,             // Wether or not the validator is required.
   *   "minimumLength":int,         // Minimum length of the input string.
   *   "maximumLength":int,         // Maximum length of the input string.
   *   "fractionLength":int,        // The number of nubmers after the dot if input is a number.
   *   "radix": int,                // Radix for the when type is number: @See http://en.wikipedia.org/wiki/Radix. Is not used a by jarbInput.
   *   "pattern": string,           // The regex in Java form the input must be valid for. Is not used a by jarbInput.
   *   "min": int,                  // The maximum int value, is not used by jarbInput.
   *   "max": int,                  // The minimum int value, is not used by jarbInput.
   *   "name": string               // The name of the property this validator represents.
   * }
   *
   */
  let _constraints = null;

  // Holds the callbacks to 'jarbInput' directives who want to know when to update.
  let _onConstraintsChangedCallbacks = [];

  return {
    loadConstraints,
    getConstraints,
    setConstraints,
    onConstraintsChanged
  };

  /**
   * @description Returns the constraints from the store
   * @return {constraints} The constraints from the store
   */
  function getConstraints() {
    return _constraints;
  }

  /**
   * @description Loads the constraints.
   * @param {url} The constraints url
   * @return {constraints} The constraints from the store
   */
  function loadConstraints(url) {
    return $http.get(url).then(function (result) {
      setConstraints(result.data);
      return result;
    });
  }

  /**
   * @description Set new constraints for the store, calls 'onConstraintsChanged' callbacks for observers.
   * @param {constraints} constraints The new constraints
   */
  function setConstraints(constraints) {
    _constraints = constraints;
    // Notify 'jarbInput' directives that the constraints have been set.
    _.each(_onConstraintsChangedCallbacks, function (callback) {
      callback(_constraints); // Give _constraints for convenience.
    });
  }

  /**
   * @description Registers a callback function for when the constraints change.
   * @param {fn(constraints) -> void} callback The function that handles the constraints changed event.
   */
  function onConstraintsChanged(callback) {
    _onConstraintsChangedCallbacks.push(callback);
  }
});
